import { Typography } from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import { NOT_APPLICABLE } from 'constants/general';

interface RecipientAccountDetailsProps {
  isDebit: boolean;
  accountNumberTail: string;
  accountName?: string;
  accountTypeLabel?: string;
}

const RecipientAccountDetails: FC<RecipientAccountDetailsProps> = ({
  isDebit,
  accountTypeLabel,
  accountNumberTail,
  accountName,
}) => {
  const intl = useIntl();
  const bankAccountLabel = accountTypeLabel ? intl.formatMessage({ id: accountTypeLabel }) : NOT_APPLICABLE;

  return (
    <div>
      <Typography variant="body2" className="text-ellipsis" fontWeight={600}>
        {!accountName
          ? `${intl.formatMessage({ id: 'label.cardEnding' })} (${accountNumberTail})`
          : `${accountName} (${accountNumberTail})`}
      </Typography>
      <Typography
        variant="body3"
        fontWeight={400}
        color="textSecondary"
        sx={isDebit ? { textTransform: 'uppercase' } : {}}
      >
        {isDebit ? intl.formatMessage({ id: 'label.debit' }) : bankAccountLabel}
      </Typography>
    </div>
  );
};

export default RecipientAccountDetails;
