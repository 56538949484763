import { Box, Menu, Typography } from '@mui/material';
import {
  ReactElement, FC, useState, MouseEvent, useRef,
} from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { Item, MenuItem, NavbarLink } from 'components/_Navbar/MoveMoneySelector/index.styled';
import { ItemRoot, NavElement } from 'components/_Navbar/NavigationTabs/index.styled';

import { usePermissions } from 'hooks/usePermissions';

import ChevronDownIcon from 'assets/icons/ChevronDownIcon';
import ManagementIcon from 'assets/icons/ManagementIcon';
import {
  TRANSACTION_APPROVAL_ROUTE,
  ACCOUNT_STATEMENTS_ROUTE,
  SCHEDULED_TRANSACTIONS_ROUTE,
  ACTIVITY_ROUTE,
  MANAGE_RECIPIENTS_ROUTE,
  ALERTS_RULES_ROUTE,
} from 'constants/clientRoutes';
import { RolePermissions } from 'constants/permissionEnums';

interface ManagementSelectorProps {
  onItemClick: () => void;
}

const ManagementSelector: FC<ManagementSelectorProps> = ({ onItemClick }): ReactElement => {
  const intl = useIntl();
  const location = useLocation();
  const { isAllowed } = usePermissions();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const ref = useRef(null);
  const matchRoute = location.pathname?.includes('management');

  const handleClickItem = (event: MouseEvent<HTMLDivElement>): void => {
    setAnchorEl(ref.current);
    event.stopPropagation();
    event.preventDefault();
    onItemClick();
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <ItemRoot onClick={handleClickItem} ref={ref}>
        <NavElement isActive={matchRoute} isOpen={open}>
          <Item isOpen={open}>
            <ManagementIcon />

            <Typography variant="body2" ml={1}>
              {intl.formatMessage({ id: 'navigation.management' })}
            </Typography>

            <Box onClick={handleClickItem} display="flex" alignItems="center" p={1}>
              <ChevronDownIcon size={20} />
            </Box>
          </Item>
        </NavElement>
      </ItemRoot>
      <Menu
        id="management-menu"
        anchorEl={anchorEl}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {isAllowed({ permission: RolePermissions.ViewScheduledTransaction }) && (
          <NavbarLink to={SCHEDULED_TRANSACTIONS_ROUTE}>
            {({ isActive }): ReactElement => (
              <MenuItem value="activity" onClick={handleClose}>
                <Typography color={isActive ? 'primary' : 'textPrimary'}>
                  {intl.formatMessage({ id: 'navigation.scheduledTransactions' })}
                </Typography>
              </MenuItem>
            )}
          </NavbarLink>
        )}
        <NavbarLink to={ACTIVITY_ROUTE}>
          {({ isActive }): ReactElement => (
            <MenuItem value="activity" onClick={handleClose}>
              <Typography color={isActive ? 'primary' : 'textPrimary'}>
                {intl.formatMessage({ id: 'navigation.transactionHistory' })}
              </Typography>
            </MenuItem>
          )}
        </NavbarLink>
        <NavbarLink to={MANAGE_RECIPIENTS_ROUTE}>
          {({ isActive }): ReactElement => (
            <MenuItem value="activity" onClick={handleClose}>
              <Typography color={isActive ? 'primary' : 'textPrimary'}>
                {intl.formatMessage({ id: 'navigation.manageRecipients' })}
              </Typography>
            </MenuItem>
          )}
        </NavbarLink>
        <NavbarLink to={TRANSACTION_APPROVAL_ROUTE}>
          {({ isActive }): ReactElement => (
            <MenuItem value="activity" onClick={handleClose}>
              <Typography color={isActive ? 'primary' : 'textPrimary'}>
                {intl.formatMessage({ id: 'navigation.transactionApproval' })}
              </Typography>
            </MenuItem>
          )}
        </NavbarLink>
        <NavbarLink to={ACCOUNT_STATEMENTS_ROUTE}>
          {({ isActive }): ReactElement => (
            <MenuItem value="activity" onClick={handleClose}>
              <Typography color={isActive ? 'primary' : 'textPrimary'}>
                {intl.formatMessage({ id: 'navigation.accountStatements' })}
              </Typography>
            </MenuItem>
          )}
        </NavbarLink>
        {isAllowed({ permission: RolePermissions.ViewAlertRules }) && (
          <NavbarLink to={ALERTS_RULES_ROUTE}>
            {({ isActive }): ReactElement => (
              <MenuItem value="activity" onClick={handleClose}>
                <Typography color={isActive ? 'primary' : 'textPrimary'}>
                  {intl.formatMessage({ id: 'navigation.alertCenter' })}
                </Typography>
              </MenuItem>
            )}
          </NavbarLink>
        )}
      </Menu>
    </>
  );
};

export default ManagementSelector;
