import { runGetApiRequest } from 'services/Api';

import { GET_ACCOUNT_STATEMENTS_API } from 'constants/apiRoutes';
import { AccountStatementsQueryParams } from 'models/accountStatements.interface';

export const getAccountStatements = (queryParams: AccountStatementsQueryParams) => {
  const requestParams = {
    ...queryParams,
    bankAccountIds: queryParams?.bankAccountIds?.join(',') || '',
  };
  return runGetApiRequest(GET_ACCOUNT_STATEMENTS_API, requestParams);
};
