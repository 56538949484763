/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const AttachmentIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5326 2.51172C12.5504 2.51172 11.5692 2.90085 10.8334 3.67708L5.49487 9.30859C3.49878 11.415 3.49878 14.8011 5.49487 16.9076C5.5564 16.9724 5.63035 17.0242 5.71232 17.0599C5.71362 17.0603 5.71492 17.0608 5.71622 17.0612C7.72732 18.967 10.8643 18.9457 12.7956 16.9076L16.5509 12.9453C16.6144 12.8825 16.6647 12.8075 16.6987 12.7248C16.7327 12.6421 16.7498 12.5535 16.7488 12.4641C16.7479 12.3747 16.729 12.2864 16.6932 12.2045C16.6575 12.1226 16.6056 12.0487 16.5407 11.9872C16.4758 11.9257 16.3992 11.8779 16.3155 11.8466C16.2317 11.8153 16.1426 11.8012 16.0533 11.805C15.964 11.8089 15.8763 11.8307 15.7956 11.8691C15.7149 11.9075 15.6427 11.9618 15.5834 12.0286L11.8282 15.9909C10.327 17.5751 7.9648 17.5751 6.46362 15.9909C4.96237 14.4066 4.96237 11.8095 6.46362 10.2253L11.8009 4.59375C12.7773 3.56356 14.2892 3.56356 15.2657 4.59375C16.2421 5.62384 16.2421 7.32408 15.2657 8.35417L10.4076 13.4805C9.95503 13.9576 9.29436 13.9573 8.84252 13.4805C8.39068 13.0036 8.39068 12.2008 8.84252 11.724L12.7501 7.60026C12.8136 7.53742 12.8639 7.46243 12.8979 7.37976C12.9319 7.29709 12.949 7.20843 12.948 7.11904C12.9471 7.02965 12.9282 6.94137 12.8924 6.85944C12.8567 6.77751 12.8048 6.70361 12.7399 6.64213C12.675 6.58064 12.5984 6.53284 12.5147 6.50155C12.431 6.47026 12.3418 6.45613 12.2525 6.46C12.1632 6.46387 12.0756 6.48566 11.9948 6.52407C11.9141 6.56248 11.842 6.61673 11.7826 6.68359L7.87377 10.8073C6.92695 11.8065 6.92695 13.398 7.87377 14.3971C7.93697 14.4636 8.01321 14.5164 8.09773 14.5521C9.05816 15.3544 10.4929 15.3272 11.3751 14.3971L16.2331 9.27083C17.6441 7.78231 17.6643 5.45281 16.3699 3.89323C16.3387 3.81303 16.2922 3.73963 16.2331 3.67708C15.4974 2.90085 14.5148 2.51172 13.5326 2.51172Z"
      fill={color}
    />
  </svg>

);

export default AttachmentIcon;
