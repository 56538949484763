import { Box } from '@mui/material';
import { ReactElement } from 'react';

import GenericModal from 'components/GenericModal';
import GenericModalAppHeader from 'components/GenericModalAppHeader';
import { IconButton } from 'containers/CreateDummyCompany/index.styled';
import FlinksIframe from 'modules/flinks/containers/FlinksIframe';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { closeFlinks } from 'store/flinks/flinksSlice';
import { showFlinksIframeSelector } from 'store/flinks/selectors';

import CloseIcon from 'assets/icons/CloseIcon';

const bodySx = {
  px: 4,
  py: 0,
  minHeight: '70vh',
  minWidth: '550px',
};

const Flinks = (): ReactElement|null => {
  const showFlinksIFrame = useAppSelector(showFlinksIframeSelector);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(closeFlinks());
    document.body.focus();
  };

  if (!showFlinksIFrame) {
    return null;
  }

  return (
    <GenericModal
      id="modal-flinks-details"
      isOpen
      maxWidth="xl"
      bodysx={bodySx}
    >
      <Box pt={4} sx={{ background: 'white', position: 'sticky', top: 0 }}>
        <GenericModalAppHeader />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="flex-end" mb={3}>
        <IconButton onClick={handleClose} aria-label="close modal" id="iconButton-close-new-company">
          <CloseIcon />
        </IconButton>
      </Box>
      <FlinksIframe />
    </GenericModal>
  );
};

export default Flinks;
