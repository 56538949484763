import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getAccountStatements } from 'services/AccountStatements';
import { resetStore } from 'store/actions';

import { ApiStatus, SortOrder } from 'constants/enums';
import { AccountStatements, AccountStatementsQueryParams } from 'models/accountStatements.interface';
import { Pagination } from 'models/pagination.interface';
import { SortOptions } from 'models/sortOptions.interface';
import { handleApiErrors } from 'utils/errorUtils';
import { AccountStatementsSchema } from 'utils/validation/accountStatementsSchema';

export interface AccountStatementsState {
  details: {
    data: AccountStatements[];
    status: ApiStatus;
  };
  sortOptions: SortOptions;
  pagination: Pagination;
  search: string;
  filters: AccountStatementsSchema;
}

const initialState: AccountStatementsState = {
  details: {
    data: [],
    status: ApiStatus.idle,
  },
  sortOptions: {
    name: 'createdAt',
    type: SortOrder.desc,
  },
  pagination: {
    totalPages: 0,
    totalElements: 0,
    page: 1,
  },
  filters: {
    bankAccounts: [],
    month: '',
    year: new Date().getFullYear().toString(),
  },
  search: '',
};

export const fetchAccountStatements = createAsyncThunk(
  'accountStatements/fetchAccountStatements',
  async (queryParams: AccountStatementsQueryParams, thunkAPI) => {
    try {
      const response = await getAccountStatements(queryParams);
      return response.data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const accountStatementsSlice = createSlice({
  name: 'accountStatements',
  initialState,
  reducers: {
    setPagination: (state, action: PayloadAction<Partial<Pagination>>) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      };
      return state;
    },
    setSort: (state, action: PayloadAction<SortOptions>) => {
      state.sortOptions = action.payload;
      return state;
    },
    setFilters: (state, action: PayloadAction<AccountStatementsSchema>) => {
      state.filters = action.payload;
      return state;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
      return state;
    },
    resetAccountStatements: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(fetchAccountStatements.pending, (state) => {
        state.details = {
          ...state.details,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchAccountStatements.fulfilled, (state, action) => {
        const {
          totalElements, totalPages, content,
        } = action.payload;

        state.details = {
          data: content,
          status: ApiStatus.idle,
        };
        state.pagination = {
          ...state.pagination,
          totalPages,
          totalElements,
        };
      })
      .addCase(fetchAccountStatements.rejected, (state) => {
        state.details = {
          ...state.details,
          status: ApiStatus.idle,
        };
      });
  },
});

export const {
  setPagination,
  setSort,
  setFilters,
  setSearch,
  resetAccountStatements,
} = accountStatementsSlice.actions;

export default accountStatementsSlice.reducer;
