import { wrapCreateBrowserRouter } from '@sentry/react';
import { createBrowserRouter } from 'react-router-dom';

import ErrorBoundary from 'components/_ErrorBoundary';
import NotFound from 'components/_NotFound';
import SwaggerDocumentation from 'components/_SwaggerDocumentation';
import IconsList from 'components/IconsList';
import KybCover from 'components/KybCover';
import KybCreateProvider from 'containers/KybCreateProvider';
import AuthLayout from 'layouts/AuthLayout';
import CustomerLayout from 'layouts/CustomerLayout';
import MainLayout from 'layouts/MainLayout';
import PortalLayout from 'layouts/PortalLayout';
import PortalSettingsLayout from 'layouts/PortalSettingsLayout';
import SettingsLayout from 'layouts/SettingsLayout';
import SuspenseLayout from 'layouts/SuspenseLayout';
import FlinksTesting from 'modules/flinks-testing';
import CreateKyb from 'modules/kyb';
import ReviewKyb from 'modules/kyb/containers/ReviewKyb';
import CustomerDetails from 'portal/customer-details';
import ProtectedRoute from 'routes/ProtectedRoute';
import UnauthenticatedRoute from 'routes/UnauthenticatedRoute';

import { SidebarContext } from 'hooks/useSidebar';

import * as routes from 'constants/clientRoutes';

import * as routesConfig from './routesConfiguration';

export const sentryCreateBrowserRouter = process.env.REACT_APP_NODE_ENV !== 'local'
  ? wrapCreateBrowserRouter(createBrowserRouter)
  : createBrowserRouter;

export const customerRouter = [
  {
    path: routes.FLINKS_TESTING,
    errorElement: <ErrorBoundary />,
    element: <FlinksTesting />,
  },
  {
    element: <CustomerLayout hasHeader />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        errorElement: <ErrorBoundary />,
        children: routesConfig.authCustomerRoutes,
      },
    ],
  },
  {
    element: <CustomerLayout width="auto" />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        errorElement: <ErrorBoundary />,
        children: routesConfig.subscriptionRoutes,
      },
    ],
  },
  {
    element: <UnauthenticatedRoute />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        errorElement: <ErrorBoundary />,
        children: routesConfig.authRoutes,
      },
    ],
  },
  {
    element: <ProtectedRoute />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        element: <IconsList />,
        path: routes.ICONS_ROUTE,
      },
      {
        element: <SwaggerDocumentation />,
        path: routes.SWAGGER_ROUTE,
      },
      {
        element: <MainLayout />,
        errorElement: <ErrorBoundary />,
        children: routesConfig.mainRoutes,
      },
      {
        element: <KybCreateProvider />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            path: routes.KYB_CREATION_ROUTE,
            element: <CreateKyb />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: routes.KYB_CREATION_REVIEW_ROUTE,
            element: <ReviewKyb />,
            errorElement: <ErrorBoundary />,
          },
        ],
      },
      {
        path: routes.KYB_COVER_ROUTE,
        element: <AuthLayout><KybCover /></AuthLayout>,
        errorElement: <ErrorBoundary />,
      },
      {
        element: <SettingsLayout context={SidebarContext.WorkspaceSettings} hasTitle />,
        errorElement: <ErrorBoundary />,
        children: routesConfig.companySettingsRoutes,
      },
      {
        element: <SettingsLayout context={SidebarContext.Alerts} />,
        errorElement: <ErrorBoundary />,
        children: routesConfig.rulesSettingsRoutes,
      },
      {
        element: <SettingsLayout context={SidebarContext.UserProfile} />,
        errorElement: <ErrorBoundary />,
        children: routesConfig.userSettingsRoutes,
      },
      {
        element: <MainLayout />,
        errorElement: <ErrorBoundary />,
        path: routes.MOVE_MONEY_ROUTE,
        children: routesConfig.moveMoneyRoutes,
      },
      {
        element: <MainLayout />,
        errorElement: <ErrorBoundary />,
        path: routes.MANAGEMENT_ROUTE,
        children: routesConfig.managementRoutes,
      },
    ],
  },
  {
    element: <SuspenseLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        errorElement: <ErrorBoundary />,
        children: routesConfig.openRoutes,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

export const operatorRouter = [
  {
    element: <UnauthenticatedRoute />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        errorElement: <ErrorBoundary />,
        children: routesConfig.authRoutes,
      },
    ],
  },
  {
    element: <ProtectedRoute />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        element: <IconsList />,
        path: routes.ICONS_ROUTE,
      },
      {
        element: <PortalLayout />,
        errorElement: <ErrorBoundary />,
        children: routesConfig.adminMainRoutes,
      },
      {
        element: <PortalLayout isMini />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            element: <CustomerDetails />,
            errorElement: <ErrorBoundary />,
            path: routes.CUSTOMER_DETAILS_ROUTE,
          },
          {
            path: routes.CUSTOMER_WORKSPACE_DETAILS_ROUTE,
            element: <CustomerDetails />,
            errorElement: <ErrorBoundary />,
          },
        ],
      },
      {
        element: <PortalSettingsLayout context={SidebarContext.UserProfile} />,
        errorElement: <ErrorBoundary />,
        children: routesConfig.userSettingsRoutes,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
];
