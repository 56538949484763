import { Pagination } from '@mui/material';
import { ChangeEvent, FC } from 'react';

import EmptySearchResults from 'components/EmptySearchResults';
import ResponsiveTable from 'components/Table';
import { TableDivider } from 'components/Table/ResponsiveTable/index.styled';

import { DEFAULT_PAGINATION_SIZE } from 'constants/general';
import { CASHFLOW_COLUMNS } from 'constants/tableColumns';
import { Pagination as PaginationProps } from 'models/pagination.interface';
import { SortOptions } from 'models/sortOptions.interface';
import { Transaction } from 'models/transaction.interface';

interface TransactionsProps {
  transactions: Transaction[];
  sortOptions: SortOptions;
  pagination: PaginationProps;
  isLoading: boolean;
  onPage: (page: number) => void;
  onSort: (columnName: string) => void;
}

const Transactions:FC<TransactionsProps> = ({
  transactions,
  sortOptions,
  pagination,
  isLoading,
  onPage,
  onSort,
}) => {
  const hasPagination = pagination.totalElements > DEFAULT_PAGINATION_SIZE;

  const handleChangePage = (event: ChangeEvent<unknown>, newPage: number) => {
    onPage(newPage);
  };

  return (
    <>
      <ResponsiveTable
        isFullWidth
        id="table-transactions"
        columns={CASHFLOW_COLUMNS}
        data={transactions}
        isRounded
        isLoading={isLoading}
        sortOptions={sortOptions}
        onSort={onSort}
      />

      {hasPagination && !isLoading && (
        <>
          <TableDivider />
          <Pagination
            count={pagination.totalPages}
            page={pagination.page}
            onChange={handleChangePage}
            sx={{ my: 5 }}
          />
        </>
      )}

      {!transactions?.length && <EmptySearchResults />}
    </>
  );
};

export default Transactions;
