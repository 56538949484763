import { useEffect, useState } from 'react';

import Loader from 'components/Loader';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getAggregationIframeUrl } from 'services/AggregationService';
import { getCompanyDetails } from 'services/KybService';
import { closeFlinks } from 'store/flinks/flinksSlice';
import { currentWorkspaceSelector } from 'store/user/selectors';

import envConfig from 'configuration/env';
import { handleApiErrors } from 'utils/errorUtils';

export const formatQueryParams = (params: Record<string, any>): string => (
  Object
    .keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&')
);

const FlinksIframe = () => {
  const [iframeUrl, setIframeUrl] = useState('');
  const dispatch = useAppDispatch();
  const customer = useAppSelector(currentWorkspaceSelector);

  const fetchDetails = async (customerId: string) => {
    try {
      const { data: companyDetails } = await getCompanyDetails(customerId);
      const countryCode = companyDetails?.addresses?.[0]?.countryCode || 'CA';

      const { data: url } = await getAggregationIframeUrl({
        demo: envConfig.env !== 'PROD',
        redirectUrl: false,
        countryCode: countryCode || 'CA',
        scheduleNightlyRefresh: true,
        includeTransactions: true,
        multipleAccounts: true,
      });

      const params = {
        demo: envConfig.env !== 'PROD',
        staticLoadingEnable: true,
        termsNoCheckbox: true,
        termsUrl: envConfig.termsUrl,
        customerName: envConfig.brandName,
      };

      setIframeUrl(`${url}&${formatQueryParams(params)}`);
    } catch (e) {
      handleApiErrors(e);
    }
  };

  useEffect(() => {
    if (customer?.id) {
      fetchDetails(customer.id);
    }
  }, [customer?.id]);

  const handleMessage = ({ data }: MessageEvent<any>) => {
    if (data.step === 'REDIRECT') {
      setTimeout(() => dispatch(closeFlinks()), 3000);
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  return (
    !iframeUrl
      ? <Loader />
      : (
        <iframe
          title="flinks"
          style={{ height: '70vh', width: '550px', border: 0 }}
          src={iframeUrl}
        />
      )
  );
};

export default FlinksIframe;
