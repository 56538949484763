import { getWeekOfMonth, getWeeksInMonth, isLastDayOfMonth } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { isNil } from 'lodash';
import { FormattedMessage } from 'react-intl';

import {
  AuthorityType,
  BalanceTrend,
  BankAccountsGroupByType,
  BankAccountsSortByType,
  DocumentType,
  CriteriaCondition,
  CustomMonthlyRecurrence,
  DateFormats,
  DefaultType,
  RecurrenceEndPeriod,
  RuleStatus,
  TransactionRecurrence,
  TransactionType,
  AddressType,
  BankAccountType,
  TransactionStatusType,
  TransactionReversalReason,
  AmountComparison,
  DateType,
  CashFlowSearchType,
} from 'constants/enums';
import { FINANCIAL_ACCOUNT_STATUSES_LABELS, REVERSAL_REASON_LABELS } from 'constants/labels';
import { formatDate, formatDayWithOrdinal } from 'utils/formatters';

export const USER_AUTHORITY_OPTIONS = [
  {
    label: <FormattedMessage id="label.owner" />,
    value: AuthorityType.userOwner,
    disabled: true,
  },
  {
    label: <FormattedMessage id="label.admin" />,
    value: AuthorityType.userAdmin,
  },
  {
    label: <FormattedMessage id="label.manager" />,
    value: AuthorityType.userManager,
  },
  {
    label: <FormattedMessage id="label.viewer" />,
    value: AuthorityType.userViewer,
  },
];

export const ALL_BANKS_OPTION = {
  code: DefaultType.all,
  name: 'label.allBanks',
  smallLogoUrl: null,
  mediumLogoUrl: null,
  primaryColor: null,
  secondaryColor: null,
};

export const COUNTRY_OPTIONS = [
  {
    label: 'USA',
    value: 'USA',
  },
  {
    label: 'CAN',
    value: 'CAN',
  },
];

export const US_OPTION = [
  {
    label: 'USA',
    value: 'USA',
  },
];

export const BANK_ACCOUNTS_SORT_BY_OPTIONS = [
  {
    label: <FormattedMessage id="label.balanceHighLow" />,
    value: BankAccountsSortByType.balanceHighLow,
  },
  {
    label: <FormattedMessage id="label.balanceLowHigh" />,
    value: BankAccountsSortByType.balanceLowHigh,
  },
  {
    label: <FormattedMessage id="label.instituteNameAZ" />,
    value: BankAccountsSortByType.instituteNameAZ,
  },
  {
    label: <FormattedMessage id="label.instituteNameZA" />,
    value: BankAccountsSortByType.instituteNameZA,
  },
];

export const BANK_ACCOUNTS_GROUP_BY_OPTIONS = [
  {
    label: <FormattedMessage id="label.accountType" />,
    value: BankAccountsGroupByType.accountType,
  },
  {
    label: <FormattedMessage id="label.bank" />,
    value: BankAccountsGroupByType.bank,
  },
  {
    label: <FormattedMessage id="label.noGrouping" />,
    value: BankAccountsGroupByType.noGrouping,
  },
];

export const BALANCE_TRENDS_OPTIONS = [
  {
    label: <FormattedMessage id="label.monthToDate" />,
    value: BalanceTrend.MonthToDate,
  },
  {
    label: <FormattedMessage id="label.lastFourWeeks" />,
    value: BalanceTrend.LastFourWeeks,
  },
  {
    label: <FormattedMessage id="label.thisQuarter" />,
    value: BalanceTrend.QtrToDate,
  },
  {
    label: <FormattedMessage id="label.last3Months" />,
    value: BalanceTrend.Last3Month,
  },
  {
    label: <FormattedMessage id="label.1year" />,
    value: BalanceTrend.LastYear,
  },
];

export const TEAM_USER_AUTHORITY_OPTIONS = [
  {
    label: <FormattedMessage id="label.all" />,
    value: DefaultType.all,
  },
  {
    label: <FormattedMessage id="label.owner" />,
    value: AuthorityType.userOwner,
  },
  {
    label: <FormattedMessage id="label.admin" />,
    value: AuthorityType.userAdmin,
  },
  {
    label: <FormattedMessage id="label.manager" />,
    value: AuthorityType.userManager,
  },
  {
    label: <FormattedMessage id="label.viewer" />,
    value: AuthorityType.userViewer,
  },
];

export const getScheduleRecurrenceOptions = (selectedDate: number | null, message?: string | null) => {
  const date = !isNil(selectedDate) ? new Date(selectedDate) : new Date();
  const weekOfMonth = getWeekOfMonth(date);
  const weeksInMonth = getWeeksInMonth(date);

  return [
    {
      label: <FormattedMessage id="label.doesNotRepeat" />,
      value: TransactionRecurrence.doesNotRepeat,
    },
    {
      label: <FormattedMessage id="label.daily" />,
      value: TransactionRecurrence.daily,
    },
    {
      label: <FormattedMessage id="label.weeklyOn" values={{ date: formatDate(date, DateFormats.onlyWeekday) }} />,
      value: TransactionRecurrence.weekly,
    },
    {
      label: (
        <FormattedMessage
          id="label.monthlyByPos"
          values={{
            interval: 1,
            day: formatDate(date, DateFormats.onlyWeekday),
            pos: weeksInMonth === weekOfMonth ? -1 : weekOfMonth,
          }}
        />
      ),
      value: TransactionRecurrence.monthly,
    },
    {
      label: <FormattedMessage id="label.annuallyOn" values={{ date: formatDate(date, DateFormats.shortDate) }} />,
      value: TransactionRecurrence.yearly,
    },
    {
      label: <FormattedMessage id="label.everyWeekday" />,
      value: TransactionRecurrence.everyWeekday,
    },
    // {
    //   label: <FormattedMessage id="label.everyBusinessDay" />,
    //   value: TransactionRecurrence.everyBusinessDay,
    // },
    ...(message ? [{
      label: message,
      value: TransactionRecurrence.customCreated,
    }] : []),
    {
      label: <FormattedMessage id="label.custom" />,
      value: TransactionRecurrence.custom,
    },
  ];
};

export const HOURS_OPTIONS = [
  {
    label: 1,
    value: 1,
  },
  {
    label: 2,
    value: 2,
  },
  {
    label: 3,
    value: 3,
  },
  {
    label: 4,
    value: 4,
  },
  {
    label: 5,
    value: 5,
  },
  {
    label: 6,
    value: 6,
  },
  {
    label: 7,
    value: 7,
  },
  {
    label: 8,
    value: 8,
  },
  {
    label: 9,
    value: 9,
  },
  {
    label: 10,
    value: 10,
  },
  {
    label: 11,
    value: 11,
  },
  {
    label: 12,
    value: 12,
  },
];

export const HOURS_24_OPTIONS = [
  {
    label: '01:00',
    value: 1,
  },
  {
    label: '02:00',
    value: 2,
  },
  {
    label: '03:00',
    value: 3,
  },
  {
    label: '04:00',
    value: 4,
  },
  {
    label: '05:00',
    value: 5,
  },
  {
    label: '06:00',
    value: 6,
  },
  {
    label: '07:00',
    value: 7,
  },
  {
    label: '08:00',
    value: 8,
  },
  {
    label: '09:00',
    value: 9,
  },
  {
    label: '10:00',
    value: 10,
  },
  {
    label: '11:00',
    value: 11,
  },
  {
    label: '12:00',
    value: 12,
  },
  {
    label: '13:00',
    value: 13,
  },
  {
    label: '14:00',
    value: 14,
  },
  {
    label: '15:00',
    value: 15,
  },
  {
    label: '16:00',
    value: 16,
  },
  {
    label: '17:00',
    value: 17,
  },
  {
    label: '18:00',
    value: 18,
  },
  {
    label: '19:00',
    value: 19,
  },
  {
    label: '20:00',
    value: 20,
  },
  {
    label: '21:00',
    value: 21,
  },
  {
    label: '22:00',
    value: 22,
  },
  {
    label: '23:00',
    value: 23,
  },
  {
    label: '24:00',
    value: 24,
  },
];

export const MINUTES_OPTIONS = [
  {
    label: '00',
    value: '00',
  },
  {
    label: '15',
    value: '15',
  },
  {
    label: '30',
    value: '30',
  },
  {
    label: '45',
    value: '45',
  },
];

export const RECURRENCE_REPEAT_PERIOD_OPTIONS = [
  {
    label: <FormattedMessage id="label.day" />,
    value: TransactionRecurrence.daily,
  },
  {
    label: <FormattedMessage id="label.week" />,
    value: TransactionRecurrence.weekly,
  },
  {
    label: <FormattedMessage id="label.month" />,
    value: TransactionRecurrence.monthly,
  },
  {
    label: <FormattedMessage id="label.year" />,
    value: TransactionRecurrence.yearly,
  },
];

export const RECURRENCE_END_PERIOD_OPTIONS = [
  {
    label: <FormattedMessage id="label.never" />,
    value: RecurrenceEndPeriod.never,
  },
  {
    label: <FormattedMessage id="label.on" />,
    value: RecurrenceEndPeriod.on,
  },
  {
    label: <FormattedMessage id="label.after" />,
    value: RecurrenceEndPeriod.after,
  },
];

export const getCustomMonthlyRecurrenceOptions = (selectedDate: number | null) => {
  const date = !isNil(selectedDate) ? new Date(selectedDate) : new Date();
  const isLastDay = isLastDayOfMonth(date);
  const weekOfMonth = getWeekOfMonth(date);

  return [
    {
      label: isLastDay
        ? <FormattedMessage id="label.monthlyOnTheLastDay" />
        : <FormattedMessage id="label.monthlyOnThe" values={{ date: formatDayWithOrdinal(date) }} />,
      value: CustomMonthlyRecurrence.monthlyOnCurrentDay,
    },
    ...(weekOfMonth < getWeeksInMonth(date)
      ? [{
        label: (
          <FormattedMessage
            id="label.monthlyByPos"
            values={{
              interval: 1,
              day: formatDate(date, DateFormats.onlyWeekday),
              pos: weekOfMonth,
            }}
          />
        ),
        value: CustomMonthlyRecurrence.monthlyOnCurrentWeek,
      }] : []),
    {
      label: (
        <FormattedMessage
          id="label.monthlyOnTheLast"
          values={{ date: formatDate(date, DateFormats.onlyWeekday) }}
        />
      ),
      value: CustomMonthlyRecurrence.monthlyOnTheLastWeek,
    },
  ];
};

export const RULE_TRANSACTION_TYPE_OPTIONS = [
  {
    label: <FormattedMessage id="label.debitWithdrawal" />,
    value: TransactionType.debit,
  },
  {
    label: <FormattedMessage id="label.creditWithdrawal" />,
    value: TransactionType.credit,
  },
];

export const RULE_TRANSACTION_STATUS_OPTIONS = [
  {
    label: <FormattedMessage id="label.enabledAlerts" />,
    value: RuleStatus.active,
  },
  {
    label: <FormattedMessage id="label.disabledAlerts" />,
    value: RuleStatus.inactive,
  },
];

export const RULE_CRITERIA_CONDITION_OPTIONS = [
  {
    label: <FormattedMessage id="label.equal" />,
    value: CriteriaCondition.equal,
  },
  {
    label: <FormattedMessage id="label.between" />,
    value: CriteriaCondition.between,
  },
  {
    label: <FormattedMessage id="label.greaterthan" />,
    value: CriteriaCondition.greaterThan,
  },
  {
    label: <FormattedMessage id="label.greaterequal" />,
    value: CriteriaCondition.greaterEqual,
  },
  {
    label: <FormattedMessage id="label.lessthan" />,
    value: CriteriaCondition.lessThan,
  },
  {
    label: <FormattedMessage id="label.lessequal" />,
    value: CriteriaCondition.lessEqual,
  },
];

export const COMPANY_ADDRESS_TYPE_OPTIONS = [
  {
    label: <FormattedMessage id="label.mailing" />,
    value: AddressType.mailing,
  },
  {
    label: <FormattedMessage id="label.physical" />,
    value: AddressType.physical,
  },
  {
    label: <FormattedMessage id="label.shipping" />,
    value: AddressType.shipping,
  },
];

export const INTERNAL_BANK_ACCOUNT_SUBTYPE_OPTIONS = [
  {
    label: <FormattedMessage id="label.checkingAccount" />,
    value: BankAccountType.checking,
  },
  {
    label: <FormattedMessage id="label.savingAccount" />,
    value: BankAccountType.saving,
  },
];

export const COMPANY_DOCUMENT_OPTIONS = [
  {
    label: <FormattedMessage id="label.formation" />,
    value: DocumentType.formation,
  },
  {
    label: <FormattedMessage id="label.businessOwnership" />,
    value: DocumentType.ownership,
  },
  {
    label: <FormattedMessage id="label.tax" />,
    value: DocumentType.tax,
  },
];

export const BENEFICIARY_DOCUMENT_OPTIONS = [
  {
    label: <FormattedMessage id="label.driverLicense" />,
    value: DocumentType.driverLicense,
  },
  {
    label: <FormattedMessage id="label.passport" />,
    value: DocumentType.passport,
  },
];

export const KOR_TRANSACTION_STATUSES_OPTIONS = [
  {
    label: <FormattedMessage id="label.all" />,
    value: DefaultType.all,
  },
  {
    label: <FormattedMessage id={FINANCIAL_ACCOUNT_STATUSES_LABELS[TransactionStatusType.approved]} />,
    value: TransactionStatusType.approved,
  },
  {
    label: <FormattedMessage id={FINANCIAL_ACCOUNT_STATUSES_LABELS[TransactionStatusType.cancelled]} />,
    value: TransactionStatusType.cancelled,
  },
  {
    label: <FormattedMessage id={FINANCIAL_ACCOUNT_STATUSES_LABELS[TransactionStatusType.cleared]} />,
    value: TransactionStatusType.cleared,
  },
  {
    label: <FormattedMessage id={FINANCIAL_ACCOUNT_STATUSES_LABELS[TransactionStatusType.declined]} />,
    value: TransactionStatusType.declined,
  },
  {
    label: <FormattedMessage id={FINANCIAL_ACCOUNT_STATUSES_LABELS[TransactionStatusType.error]} />,
    value: TransactionStatusType.error,
  },
  {
    label: <FormattedMessage id={FINANCIAL_ACCOUNT_STATUSES_LABELS[TransactionStatusType.failed]} />,
    value: TransactionStatusType.failed,
  },
  {
    label: <FormattedMessage id={FINANCIAL_ACCOUNT_STATUSES_LABELS[TransactionStatusType.new]} />,
    value: TransactionStatusType.new,
  },
  {
    label: <FormattedMessage id={FINANCIAL_ACCOUNT_STATUSES_LABELS[TransactionStatusType.pending]} />,
    value: TransactionStatusType.pending,
  },
  {
    label: <FormattedMessage id={FINANCIAL_ACCOUNT_STATUSES_LABELS[TransactionStatusType.processing]} />,
    value: TransactionStatusType.processing,
  },
  {
    label: <FormattedMessage id={FINANCIAL_ACCOUNT_STATUSES_LABELS[TransactionStatusType.settled]} />,
    value: TransactionStatusType.settled,
  },
  {
    label: <FormattedMessage id={FINANCIAL_ACCOUNT_STATUSES_LABELS[TransactionStatusType.reversed]} />,
    value: TransactionStatusType.reversed,
  },
  {
    label: <FormattedMessage id={FINANCIAL_ACCOUNT_STATUSES_LABELS[TransactionStatusType.depositWithheld]} />,
    value: TransactionStatusType.depositWithheld,
  },
];

export const REVERSAL_REASONS_OPTIONS = [
  {
    label: <FormattedMessage id={REVERSAL_REASON_LABELS[TransactionReversalReason.duplicatePayment]} />,
    value: TransactionReversalReason.duplicatePayment,
  },
  {
    label: <FormattedMessage id={REVERSAL_REASON_LABELS[TransactionReversalReason.incorrectRecipient]} />,
    value: TransactionReversalReason.incorrectRecipient,
  },
  {
    label: <FormattedMessage id={REVERSAL_REASON_LABELS[TransactionReversalReason.incorrectAmount]} />,
    value: TransactionReversalReason.incorrectAmount,
  },
];

export const AMOUNT_CONDITION_OPTIONS = [
  {
    label: 'label.equal',
    value: AmountComparison.equal,
    requiresIntl: true,
    capitalize: true,
  },
  {
    label: 'label.between',
    value: AmountComparison.between,
    requiresIntl: true,
    capitalize: true,
  },
  {
    label: 'label.greaterthan',
    value: AmountComparison.greaterThan,
    requiresIntl: true,
    capitalize: true,
  },
  {
    label: 'label.greaterequal',
    value: AmountComparison.greaterEqual,
    requiresIntl: true,
    capitalize: true,
  },
  {
    label: 'label.lessthan',
    value: AmountComparison.lessThan,
    requiresIntl: true,
    capitalize: true,
  },
  {
    label: 'label.lessequal',
    value: AmountComparison.lessEqual,
    requiresIntl: true,
    capitalize: true,
  },
];

export const FILTER_TRANSACTION_TYPE_OPTIONS = [
  {
    label: <FormattedMessage id="label.credits&Debits" />,
    value: DefaultType.all,
  },
  {
    label: <FormattedMessage id="label.credits" />,
    value: CashFlowSearchType.credit,
  },
  {
    label: <FormattedMessage id="label.debits" />,
    value: CashFlowSearchType.debit,
  },
];

export const DATE_TYPE_OPTIONS = [
  {
    label: <FormattedMessage id="label.day1" />,
    value: DateType.oneDay,
  },
  {
    label: <FormattedMessage id="label.days7" />,
    value: DateType.sevenDays,
  },
  {
    label: <FormattedMessage id="label.days30" />,
    value: DateType.oneMonth,
  },
  {
    label: <FormattedMessage id="label.days120" />,
    value: DateType.fourMonths,
  },
  {
    label: <FormattedMessage id="label.mtdMonthToDate" />,
    value: DateType.monthToDate,
  },
  {
    label: <FormattedMessage id="label.ytdYearToDate" />,
    value: DateType.yearToDate,
  },
  {
    label: <FormattedMessage id="label.customDateRange" />,
    value: DateType.custom,
  },
];
export const MONTH_OPTIONS = [
  {
    label: enUS.localize.month(0, { width: 'wide' }),
    value: '1',
  },
  {
    label: enUS.localize.month(1, { width: 'wide' }),
    value: '2',
  },
  {
    label: enUS.localize.month(2, { width: 'wide' }),
    value: '3',
  },
  {
    label: enUS.localize.month(3, { width: 'wide' }),
    value: '4',
  },
  {
    label: enUS.localize.month(4, { width: 'wide' }),
    value: '5',
  },
  {
    label: enUS.localize.month(5, { width: 'wide' }),
    value: '6',
  },
  {
    label: enUS.localize.month(6, { width: 'wide' }),
    value: '7',
  },
  {
    label: enUS.localize.month(7, { width: 'wide' }),
    value: '8',
  },
  {
    label: enUS.localize.month(8, { width: 'wide' }),
    value: '9',
  },
  {
    label: enUS.localize.month(9, { width: 'wide' }),
    value: '10',
  },
  {
    label: enUS.localize.month(10, { width: 'wide' }),
    value: '11',
  },
  {
    label: enUS.localize.month(11, { width: 'wide' }),
    value: '12',
  },
];

export const generateYearOptionsDescending = (startYear: number) => {
  const currentYear = new Date().getFullYear();
  return Array.from(
    { length: currentYear - startYear + 1 },
    (_, i) => ({ value: (currentYear - i)?.toString(), label: currentYear - i }),
  );
};
