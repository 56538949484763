/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const DocumentIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.172 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V8.828C20 8.298 19.789 7.789 19.414 7.414L14.586 2.586C14.211 2.211 13.702 2 13.172 2ZM15 18H9C8.448 18 8 17.552 8 17C8 16.448 8.448 16 9 16H15C15.552 16 16 16.448 16 17C16 17.552 15.552 18 15 18ZM15 14H9C8.448 14 8 13.552 8 13C8 12.448 8.448 12 9 12H15C15.552 12 16 12.448 16 13C16 13.552 15.552 14 15 14ZM13 9V3.5L18.5 9H13Z" fill={color} />
  </svg>
);

export default DocumentIcon;
