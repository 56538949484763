import { Box, Divider, Typography } from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import DownloadIcon from 'assets/icons/DownloadIcon';
import { AccountStatements } from 'models/accountStatements.interface';
import { forceDownload } from 'utils/downloadFile';

interface DownloadStatementProps {
  statement: AccountStatements;
}

const DownloadStatement:FC<DownloadStatementProps> = ({ statement }) => {
  const intl = useIntl();

  const downloadCSV = () => {
    forceDownload(statement.csvPath, 'Statements');
  };

  const downloadPDF = () => {
    forceDownload(statement.pdfPath, 'Statements');
  };

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <Typography variant="table" mr={4}>
        {intl.formatMessage(({ id: 'label.downloadStatement' }))}
      </Typography>

      <Box className="pointer" display="flex" alignItems="center" gap={2} onClick={downloadPDF}>
        <DownloadIcon />
        <Typography
          variant="table"
          color="primary"
          fontWeight={700}
          className="underline"
        >
          PDF
        </Typography>
      </Box>

      <Divider sx={{ height: '30px' }} orientation="vertical" />

      <Box className="pointer" display="flex" alignItems="center" gap={2} onClick={downloadCSV}>
        <DownloadIcon />
        <Typography
          variant="table"
          color="primary"
          fontWeight={700}
          className="underline"
        >
          CSV
        </Typography>
      </Box>
    </Box>
  );
};

export default DownloadStatement;
