import { Typography } from '@mui/material';
import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

import {
  Background,
  CardMedia,
  Header,
} from 'components/GenericModalAppHeader/index.styled';

import LogoText from 'assets/images/logo-text-white-beta.svg';
import envConfig from 'configuration/env';

const GenericModalAppHeader = (): ReactElement|null => {
  const intl = useIntl();
  return (
    <Header>
      <CardMedia
        component="img"
        src={LogoText}
        alt={envConfig.brandName}
      />
      <Typography
        variant="label2"
        fontWeight={600}
        color="general.lightGreen5"
        sx={{ opacity: 0.9, marginLeft: '35px', display: 'block' }}
      >
        {intl.formatMessage({ id: 'label.centralizeYourAccounts' }, { appName: envConfig.brandName })}
      </Typography>
      <Background />
    </Header>
  );
};

export default GenericModalAppHeader;
