import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { type ReactElement } from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CloseToast from 'components/CloseToast';
import AppProviders from 'containers/AppProviders';
import { UserProvider } from 'context/UserContext';
import Flinks from 'modules/flinks';

import useIntercom from 'hooks/useIntercom';
import { store } from 'store';

import ToastContainer from 'assets/themes/styled/toastContainer.styled';
import { DEFAULT_LANGUAGE } from 'constants/general';
import AppLocale from 'languages';

import AppRouter from './AppRouter';
import AppTheme from './AppTheme';

const App = (): ReactElement => {
  const defaultLang = AppLocale[DEFAULT_LANGUAGE];

  useIntercom();

  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <IntlProvider
          locale={defaultLang.locale}
          defaultLocale={defaultLang.locale}
          messages={defaultLang.messages}
        >
          <UserProvider>
            <AppTheme>
              <Flinks />
              <AppProviders />
              <AppRouter />
              <ToastContainer
                position="top-right"
                autoClose={15000}
                closeButton={CloseToast}
                closeOnClick
                pauseOnHover
                hideProgressBar
                transition={Slide}
              />
            </AppTheme>
          </UserProvider>
        </IntlProvider>
      </LocalizationProvider>
    </Provider>
  );
};

export default App;
