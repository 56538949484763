import { Box, Menu, Typography } from '@mui/material';
import {
  ReactElement, FC, useState, MouseEvent, useRef,
} from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { Item, MenuItem, NavbarLink } from 'components/_Navbar/MoveMoneySelector/index.styled';
import { ItemRoot, NavElement } from 'components/_Navbar/NavigationTabs/index.styled';

import { usePermissions } from 'hooks/usePermissions';

import ChevronDownIcon from 'assets/icons/ChevronDownIcon';
import MoveMoneyIcon from 'assets/icons/MoveMoneyIcon';
import {
  CREATE_TRANSACTION_ROUTE,
  CREATE_BATCH_TRANSACTIONS_ROUTE,
} from 'constants/clientRoutes';
import { RolePermissions } from 'constants/permissionEnums';

interface MoveMoneySelectorProps {
  onItemClick: () => void;
}

const MoveMoneySelector: FC<MoveMoneySelectorProps> = ({ onItemClick }): ReactElement => {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const { isAllowed } = usePermissions();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const ref = useRef(null);
  const matchRoute = location.pathname?.includes('move-money');

  const handleClickItem = (event: MouseEvent<HTMLDivElement>): void => {
    setAnchorEl(ref.current);
    event.stopPropagation();
    event.preventDefault();
    onItemClick();
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleRedirect = (): void => {
    if (isAllowed({ permission: RolePermissions.CreateTransactions })) {
      handleClose();
      navigate(CREATE_TRANSACTION_ROUTE);
      return;
    }

    setAnchorEl(ref.current);
    onItemClick();
  };

  return (
    <>
      <ItemRoot onClick={handleRedirect} ref={ref}>
        <NavElement isActive={matchRoute} isOpen={open}>
          <Item isOpen={open}>
            <MoveMoneyIcon />

            <Typography variant="body2" ml={1}>
              {intl.formatMessage({ id: 'navigation.moveMoney' })}
            </Typography>

            <Box onClick={handleClickItem} display="flex" alignItems="center" p={1}>
              <ChevronDownIcon size={20} />
            </Box>
          </Item>
        </NavElement>
      </ItemRoot>
      <Menu
        id="move-money-menu"
        anchorEl={anchorEl}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {isAllowed({ permission: RolePermissions.CreateTransactions }) && (
          <NavbarLink to={CREATE_TRANSACTION_ROUTE}>
            {({ isActive }): ReactElement => (
              <MenuItem value="create" onClick={handleClose}>
                <Typography color={isActive ? 'primary' : 'textPrimary'}>
                  {intl.formatMessage({ id: 'navigation.createTransaction' })}
                </Typography>
              </MenuItem>
            )}
          </NavbarLink>
        )}
        {isAllowed({ permission: RolePermissions.CreateTransactions }) && (
          <NavbarLink to={CREATE_BATCH_TRANSACTIONS_ROUTE}>
            {({ isActive }): ReactElement => (
              <MenuItem value="create" onClick={handleClose}>
                <Typography color={isActive ? 'primary' : 'textPrimary'}>
                  {intl.formatMessage({ id: 'navigation.createBatchPayments' })}
                </Typography>
              </MenuItem>
            )}
          </NavbarLink>
        )}
      </Menu>
    </>
  );
};

export default MoveMoneySelector;
