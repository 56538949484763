/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const UploadCloudIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.355 11.4133C20.674 7.75719 17.641 5 14 5C11.108 5 8.603 6.74144 7.352 9.28825C4.343 9.6325 2 12.3376 2 15.625C2 19.1461 4.686 22 8 22H21C23.761 22 26 19.6211 26 16.6875C26 13.8825 23.948 11.6098 21.355 11.4133ZM14 9.25L18.146 13.6551C18.461 13.9898 18.238 14.5625 17.793 14.5625H16V18.2812C16 18.5745 15.776 18.8125 15.5 18.8125H12.5C12.224 18.8125 12 18.5745 12 18.2812V14.5625H10.207C9.762 14.5625 9.538 13.9898 9.853 13.6551L14 9.25Z"
      fill={color}
    />
  </svg>

);

export default UploadCloudIcon;
