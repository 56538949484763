import MuiIconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

export const IconButton = styled(MuiIconButton)(({ theme }) => ({
  padding: theme.spacing(1),

  '& svg': {
    height: '20px',
    width: '20px',

    '& path': {
      fill: theme.palette.general.darkGrey1,
    },
  },
}));
