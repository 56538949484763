import { useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

import { CashFlowFilterHeader } from 'models/cashFlow.interface';
import { MAX_VISIBLE_ACCOUNTS, MAX_VISIBLE_ACCOUNTS_SUMMARY } from 'modules/insights-summary/constants';

export const useMaxAccounts = (filters: CashFlowFilterHeader, all: number, asChips?: boolean) => {
  const [maxAccounts, setMaxAccounts] = useState<number>(MAX_VISIBLE_ACCOUNTS);
  const theme = useTheme();
  const matchesScreenSizeMd = useMediaQuery(theme.breakpoints.down('md'));
  const matchesScreenSizeLg = useMediaQuery(theme.breakpoints.down('lg'));
  const matchesScreenSizeCustom = useMediaQuery(theme.breakpoints.down(1600));

  useEffect(() => {
    if (asChips) {
      setMaxAccounts(MAX_VISIBLE_ACCOUNTS_SUMMARY);
      return;
    }

    if (!filters?.type && !filters?.amountCondition && !filters?.keywords) {
      if (matchesScreenSizeMd) {
        setMaxAccounts(1);
      } else if (matchesScreenSizeLg) {
        setMaxAccounts(2);
      } else {
        setMaxAccounts(all);
      }
    }

    if (filters?.type && (filters?.amountCondition || filters?.keywords)) {
      if (matchesScreenSizeMd) {
        setMaxAccounts(0);
      } else if (matchesScreenSizeLg) {
        setMaxAccounts(1);
      } else if (matchesScreenSizeCustom) {
        setMaxAccounts(2);
      } else {
        setMaxAccounts(5);
      }
    }

    if (filters?.type || filters?.amountCondition || filters?.keywords) {
      if (matchesScreenSizeMd) {
        setMaxAccounts(0);
      } else if (matchesScreenSizeLg) {
        setMaxAccounts(1);
      } else if (matchesScreenSizeCustom) {
        setMaxAccounts(3);
      } else {
        setMaxAccounts(5);
      }
    }
  }, [
    all,
    asChips,
    matchesScreenSizeMd,
    matchesScreenSizeLg,
    matchesScreenSizeCustom,
  ]);

  return {
    maxAccounts,
  };
};
