import { BankAccount } from 'models/bankAccount.interface';

export const getSortedList = (accounts: BankAccount[], activeBankAccount: string) => (
  accounts.sort((a, b) => {
    if (a.id === activeBankAccount) {
      return -1;
    }

    if (b.id === activeBankAccount) {
      return 1;
    }

    return 0;
  })
);
