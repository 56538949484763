import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

const FlinksTesting = lazy(
  () => CustomLazyLoader(
    () => (
      import(/* webpackChunkName: 'FlinksTesting' */ 'modules/flinks-testing/containers/FlinksTesting')
    ),
    'FlinksTesting',
  ),
);

export default FlinksTesting;
