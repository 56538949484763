/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const StatementsIcon = ({ color = 'currentColor', size = 16 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.73331 1.59998C3.59186 1.59999 3.45622 1.65618 3.3562 1.7562C3.25618 1.85622 3.19999 1.99186 3.19998 2.13331V11.2H2.13331C1.99186 11.2 1.85622 11.2562 1.7562 11.3562C1.65618 11.4562 1.59999 11.5919 1.59998 11.7333C1.59998 13.1997 2.80023 14.4 4.26664 14.4H11.2C11.2177 14.3998 11.2355 14.3988 11.2531 14.3969C12.695 14.368 13.8666 13.1818 13.8666 11.7333V2.13331C13.8666 1.99186 13.8104 1.85622 13.7104 1.7562C13.6104 1.65618 13.4748 1.59999 13.3333 1.59998H3.73331ZM4.26664 2.66664H12.8V11.7333C12.8 12.6232 12.0898 13.3333 11.2 13.3333C10.3101 13.3333 9.59998 12.6232 9.59998 11.7333C9.59996 11.5919 9.54377 11.4562 9.44375 11.3562C9.34373 11.2562 9.20809 11.2 9.06664 11.2H4.26664V2.66664ZM5.86664 4.26664V5.33331H6.93331V4.26664H5.86664ZM7.99998 4.26664V5.33331H11.2V4.26664H7.99998ZM5.86664 6.39998V7.46664H6.93331V6.39998H5.86664ZM7.99998 6.39998V7.46664H11.2V6.39998H7.99998ZM5.86664 8.53331V9.59998H6.93331V8.53331H5.86664ZM7.99998 8.53331V9.59998H11.2V8.53331H7.99998Z"
      fill={color}
    />
  </svg>

);

export default StatementsIcon;
