import { createSlice } from '@reduxjs/toolkit';

import { resetStore } from 'store/actions';

export interface FlinksState {
  isOpen: boolean;
}

const initialState: FlinksState = {
  isOpen: false,
};

export const flinksSlice = createSlice({
  name: 'flinks',
  initialState,
  reducers: {
    openFlinks: (state) => {
      state.isOpen = true;
      return state;
    },
    closeFlinks: (state) => {
      state.isOpen = false;
      return state;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(resetStore, () => initialState);
  },
});

export const {
  openFlinks,
  closeFlinks,
} = flinksSlice.actions;

export default flinksSlice.reducer;
