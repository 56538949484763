import {
  runDeleteApiRequest,
  runGetApiRequest,
  runPatchApiRequest,
  runPostApiRequest,
  runUpdateApiRequest,
  runUploadApiRequest,
} from 'services/Api';

import {
  SCHEDULED_TRANSACTIONS_API,
  TRANSACTION_SEARCH_API,
  TRANSACTION_PAYMENT_REASONS_API,
  CANCEL_SCHEDULED_TRANSACTION_API,
  SUSPEND_TRANSACTION_API,
  UNSUSPEND_TRANSACTION_API,
  CREATE_TRANSACTION_API,
  SCHEDULED_TRANSACTION_DETAILS_API,
  UPDATE_SCHEDULED_TRANSACTION_API,
  UPDATE_TRANSACTION_API,
  TRANSACTION_DETAILS_API,
  TRANSACTIONS_API,
  CANCEL_TRANSACTION_API,
  REVERSE_TRANSACTION_API,
  TRANSACTION_DOCUMENTS_API,
  UPLOAD_TRANSACTION_DOCUMENTS_API,
  TRANSACTION_OPTIONS_API,
} from 'constants/apiRoutes';
import { CreateTransactionPayload } from 'models/transaction.interface';
import { formatRoute } from 'utils/formatters';

export const getScheduledTransactionDetails = (transactionId: string, type: string) => (
  runGetApiRequest(formatRoute(SCHEDULED_TRANSACTION_DETAILS_API, { transactionId }), { type })
);

export const getScheduledTransactions = (queryParams: { [key: string]: string | number }) => (
  runGetApiRequest(SCHEDULED_TRANSACTIONS_API, queryParams)
);

export const getTransactionSearch = (params: { [key: string]: string | number | boolean }) => (
  runGetApiRequest(TRANSACTION_SEARCH_API, params)
);

export const getTransactions = (params: { [key: string]: string | number | boolean }) => (
  runGetApiRequest(TRANSACTIONS_API, params)
);

export const getTransactionDetails = (transactionId: string, type: string) => (
  runGetApiRequest(formatRoute(TRANSACTION_DETAILS_API, { transactionId }), { type })
);

export const archiveTransaction = (transactionId: string, { archived } : { archived : boolean }) => {
  const endpoint = formatRoute(TRANSACTION_DETAILS_API, { transactionId });
  return runPatchApiRequest(endpoint, {}, { archived });
};

export const getPaymentReasons = () => (
  runGetApiRequest(TRANSACTION_PAYMENT_REASONS_API)
);

export const cancelScheduledTransaction = (transactionId: string, type: string) => {
  const endpoint = formatRoute(CANCEL_SCHEDULED_TRANSACTION_API, { transactionId });
  return runDeleteApiRequest(endpoint, { type });
};

export const cancelTransaction = (transactionId: string, type: string) => {
  const endpoint = formatRoute(CANCEL_TRANSACTION_API, { transactionId });
  return runPostApiRequest(endpoint, {}, { type });
};

export const reverseTransaction = (transactionId: string, data: Record<string, string|number>, type?: string) => {
  const endpoint = formatRoute(REVERSE_TRANSACTION_API, { transactionId });
  return runPostApiRequest(endpoint, data, { type });
};

export const suspendTransaction = (transactionId: string, type: string) => {
  const endpoint = formatRoute(SUSPEND_TRANSACTION_API, { transactionId });
  return runPostApiRequest(endpoint, {}, { type });
};

export const unsuspendTransaction = (transactionId: string, type: string) => {
  const endpoint = formatRoute(UNSUSPEND_TRANSACTION_API, { transactionId });
  return runPostApiRequest(endpoint, {}, { type });
};

export const createNewTransaction = (data: CreateTransactionPayload) => (
  runPostApiRequest(CREATE_TRANSACTION_API, data)
);

export const updateScheduledTransaction = (data: CreateTransactionPayload, transactionId: string, type: string) => (
  runUpdateApiRequest(formatRoute(UPDATE_SCHEDULED_TRANSACTION_API, { transactionId }), data, { type })
);

export const updateTransaction = (data: CreateTransactionPayload, transactionId: string, type: string) => (
  runUpdateApiRequest(formatRoute(UPDATE_TRANSACTION_API, { transactionId }), data, { type })
);

export const getTransactionDocuments = (transactionId: string) => (
  runGetApiRequest(formatRoute(TRANSACTION_DOCUMENTS_API, { transactionId }))
);

export const uploadTransactionDocument = async (
  transactionId: string,
  payload: { file: File; dto: any },
) => {
  const formData = new FormData();
  formData.append('file', payload.file);
  formData.append('dto', new Blob(
    [JSON.stringify(payload.dto)],
    {
      type: 'application/json',
    },
  ));

  const endpoint = formatRoute(UPLOAD_TRANSACTION_DOCUMENTS_API, { transactionId });
  return runUploadApiRequest(endpoint, formData);
};

export const getTransactionOptions = (payload: any) => (
  runPostApiRequest(TRANSACTION_OPTIONS_API, payload)
);
